import React from "react"
import { Container, Flex, Box, Text, Image } from "@chakra-ui/react"

const partners = [
  {
    title: "Entrepreneurs and Inventors",
    desc: "Projects associated with bringing new incentives and ideas in Web3 Space",
    icon: "https://xord.solutions/wp-content/uploads/2022/05/investors.png",
  },
  {
    title: "Entrepreneurs and Inventors",
    desc: "Projects associated with bringing new incentives and ideas in Web3 Space",
    icon: "https://xord.solutions/wp-content/uploads/2022/05/investors.png",
  },
  {
    title: "Entrepreneurs and Inventors",
    desc: "Projects associated with bringing new incentives and ideas in Web3 Space",
    icon: "https://xord.solutions/wp-content/uploads/2022/05/investors.png",
  },
  {
    title: "Entrepreneurs and Inventors",
    desc: "Projects associated with bringing new incentives and ideas in Web3 Space",
    icon: "https://xord.solutions/wp-content/uploads/2022/05/investors.png",
  },
]

const PartnerItem = ({ title, desc, icon }) => {
  return (
    <Box
      flex={1}
      justifyContent="center"
      textAlign={"center"}
      p={{ base: "10px", sm: "10px", lg: "15px" }}
    >
      <Image w={"80px"} m={"auto"} src={icon} />
      <Text as={"h4"} fontSize="16px" my={"15px"} fontWeight="600">
        {title}
      </Text>
      <Text as={"para"}>{desc}</Text>
    </Box>
  )
}

export default function OurTrueIntention() {
  return (
    <Box background={"#fffff"} py={{ base: 20, md: 28 }}>
      <Container maxW={"full"}>
        <Flex
          flex={1}
          align={"center"}
          direction={{ base: "column", md: "row" }}
          w={{ sm: "100%", lg: "80%" }}
        >
          <Box mb={70}>
            <Text
              lineHeight={1.1}
              fontWeight={700}
              color={"blackAlpha.900"}
              mb={5}
              fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
              as={"h2"}
            >
              Our True Intention
            </Text>
            <Text color={"blackAlpha.900"} fontSize="20">
              We visualise web3 as a revolutionary space to regenerate the
              concept of internet and economy. Our intention is to bring people
              with the same vision to align with us under one umbrella of Web3
              development and create an equilibrium of trustless future.
            </Text>
          </Box>
        </Flex>
        <Box my={{ base: "15px", sm: "15px", lg: "30px" }}>
          <Text
            lineHeight={1.1}
            fontWeight={700}
            color={"blackAlpha.900"}
            fontSize={{ base: "3xl", sm: "4xl", lg: "30px" }}
            as={"h3"}
          >
            Our Partner Persona
          </Text>
          <Flex
            color={"black"}
            direction={{ base: "column", sm: "column", lg: "row" }}
            pt={20}
            justifyContent={"space-between"}
          >
            {partners.map(partner => (
              <PartnerItem
                title={partner.title}
                desc={partner.desc}
                icon={partner.icon}
              />
            ))}
          </Flex>
        </Box>
      </Container>
    </Box>
  )
}
