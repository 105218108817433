import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from "../components/breadcrumb"
import TrueIntention from "../components/who-we-work-with/ourTrueIntention"
import Projects from "../components/who-we-work-with/projects"
const WhoWeWorkWith = () => (
  <Layout>
    <Breadcrumb
      description="Xord Solutions perceive blockchain as a source that constitutes infinite possibilities that exceed further from the mainstream use cases. We build blockchain products and give solutions along with the development of unique projects."
      title="Who We Work With"
      image="who-we-work-with-breadcrumb-banner.png"
    />
    <TrueIntention />
    <Projects />
  </Layout>
)

export const Head = () => <Seo title="Home" />

export default WhoWeWorkWith
