import React from "react"
import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Image,
} from "@chakra-ui/react"
import { AiOutlineArrowRight } from "react-icons/ai"
const ProjectItem = ({ text, icon }) => {
  return (
    <Stack direction={"row"} align={"center"} w={"full"}>
      <Flex w={"60px"} h={"60px"} mr={"10px"}>
        <Image
          w={"100%"}
          h={"100%"}
          src="https://xord.solutions/wp-content/uploads/2022/05/annonymus-client.png"
        />
      </Flex>
      <Text w={"85%"} fontWeight={600}>
        {text}
      </Text>
    </Stack>
  )
}

export default function Projects() {
  return (
    <Box background={"#0a0a0a"} py={{ base: 20, md: 28 }}>
      <Container maxW={"full"}>
        <Flex
          flex={1}
          align={"center"}
          justify={"space-between"}
          direction={{ base: "column", md: "row" }}
        >
          <Stack spacing={6} px={{ base: 10, md: 15 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={700}
              fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
            >
              <Text as={"span"} color="white" lineHeight="1.2">
                Projects We Don't Associate With
              </Text>
            </Heading>
          </Stack>
          <Box w={"full"} px={{ base: "10px", md: 15 }} color="white">
            <Stack spacing={4} fontSize="20px">
              <ProjectItem text={"Anonymous clients with no real identity"} />
              <ProjectItem
                text={
                  "Fraudulent projects with no base of creation or incentive behind it"
                }
              />
              <ProjectItem
                text={"Not well researched and put through projects"}
              />
            </Stack>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}
